<ng-template #dialogContainer></ng-template>

<p-dialog header="{{ 'magusApp.invoiceIn.accountsBalanceArrange.dialogHeader' | translate }}" [modal]="true" [(visible)]="isVisible" appendTo="body" styleClass="disable-scroll">
  <div class="row justify-content-center update-dialog-content">
    <div class="col-4">
      <form>
        <div class="row mb-2">
          <div class="col" style="margin-right: auto">
            <label
              class="form-label"
              authTranslate="magusApp.invoiceIn.accountsBalanceArrange.buyerFlag"
              for="field_sellerIndicator"
              >Buyer Flag</label
            >
            <input
              type="checkbox"
              class="form-check"
              name="buyerFlag"
              id="field_buyerFlag"
              [(ngModel)]="buyerFlag"
            />
          </div>
          <div class="col" style="margin-right: auto">
            <label
              class="form-label"
              authTranslate="magusApp.invoiceIn.accountsBalanceArrange.supplierFlag"
              for="field_sellerIndicator"
              >Supplier Flag</label
            >
            <input
              type="checkbox"
              class="form-check"
              name="supplierFlag"
              id="field_supplierFlag"
              [(ngModel)]="supplierFlag"
            />
          </div>
        </div>
      </form>
    </div>
  </div>

  <p-footer>
    <div class="pt-3">
      <button type="button" id="cancel" class="btn btn-secondary" (click)="previousState()">
        <fa-icon icon="ban"></fa-icon>&nbsp;<span authTranslate="entity.action.cancel">Cancel</span>
      </button>
      <button type="button" class="btn btn-info" (click)="determineAccountType()">
        <fa-icon icon="save"></fa-icon>&nbsp;<span authTranslate="magusApp.invoiceIn.accountsBalanceArrange.butonLabel">Save</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
