import * as dayjs from 'dayjs';
import { IDoc } from 'app/entities/doc/doc.model';
import { IInvoiceInLine } from 'app/entities/invoice-in/invoice-in-lines/invoice-in-line.model';
import { IPartner } from 'app/entities/partner/partner.model';
import { ICountry } from 'app/entities/country/country.model';
import { IPaymentMethod } from 'app/entities/payment-method/payment-method.model';
import { IGlCategory } from 'app/entities/gl-category/gl-category.model';
import { ICommonVocabulary } from 'app/entities/common-vocabulary/common-vocabulary.model';
import { ManageInvoiceOperationType } from 'app/entities/enumerations/manage-invoice-operation-type.model';
import { InvoiceCategoryType } from 'app/entities/enumerations/invoice-category-type.model';
import { InvoiceAppearanceType } from 'app/entities/enumerations/invoice-appearance-type.model';
import { ProductFeeOperationType } from 'app/entities/enumerations/product-fee-operation-type.model';
import { InvoiceBalanceState } from 'app/entities/enumerations/invoice-balance-state.model';
import { IDocFile } from '../doc-file/doc-file.model';
import { IDocumentReference } from '../invoice-out/document-reference/document-reference.model';

export interface IInvoiceIn {
  id?: number;
  appId?: string;
  invoiceType?: string | null;
  invoiceNumber?: string | null;
  originalInvoiceNumber?: string | null;
  invoiceOperation?: ManageInvoiceOperationType | null;
  completenessIndicator?: boolean | null;
  referencedInvoiceId?: number | null;
  referencedInvoiceNumber?: string | null;
  modificationIndex?: number | null;
  supplierBankAccountNumber?: string | null;
  suppIndividualExemption?: boolean | null;
  suppSmallBusinessIndicator?: boolean | null;
  suppCashAccountingIndicator?: boolean | null;
  invoiceCategory?: InvoiceCategoryType | null;
  invoiceIssueDate?: dayjs.Dayjs;
  deliveryDate?: dayjs.Dayjs;
  deliveryPeriodStart?: dayjs.Dayjs | null;
  deliveryPeriodEnd?: dayjs.Dayjs | null;
  accountingDeliveryDate?: dayjs.Dayjs | null;
  periodicalSettlement?: boolean | null;
  paymentDate?: dayjs.Dayjs;
  currencyCode?: string | null;
  vatExchangeRate?: number | null;
  accountingExchangeRate?: number | null;
  invoiceAppearance?: InvoiceAppearanceType | null;
  electronicInvoiceHash?: string | null;
  orderNumber?: string | null;
  orders?: IDocumentReference[] | null;
  inventoryVouchers?: IDocumentReference[] | null;
  productFeeOperation?: ProductFeeOperationType | null;
  pfEvidenceDocumentNo?: string | null;
  pfEvidenceDocumentDate?: dayjs.Dayjs | null;
  pfObligatedName?: string | null;
  pfObligatedRegion?: string | null;
  pfObligatedPostalCode?: string | null;
  pfObligatedCity?: string | null;
  pfObligatedAdditionalAddressDetail?: string | null;
  pfObligatedTaxNumber?: string | null;
  additionalData?: string | null;
  comment?: string | null;
  roundingIndicator?: boolean | null;
  invoiceNetAmount?: number | null;
  invoiceNetAmountLC?: number | null;
  invoiceVatAmount?: number | null;
  invoiceVatAmountLC?: number | null;
  invoiceRounding?: number | null;
  invoiceGrossAmount?: number | null;
  invoiceGrossAmountLC?: number | null;
  navTransactionId?: string | null;
  glAccountNumber?: string | null;
  intrastatFlag?: boolean | null;
  intrastatParityPlace?: string | null;
  intrastatExchangeRate?: number | null;
  vatYearMonth?: number | null;
  glYearMonth?: number | null;
  productFeeYearMonth?: number | null;
  intrastatYearMonth?: number | null;
  glPostFlag?: boolean | null;
  balance?: number | null;
  balanceLC?: number | null;
  balanceState?: InvoiceBalanceState | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
  docFileId?: number | null;
  doc?: IDoc | null;
  docFiles?: IDocFile[] | null;
  invoiceInLines?: IInvoiceInLine[] | null;
  partner?: IPartner | null;
  pfObligatedCountry?: ICountry | null;
  paymentMethod?: IPaymentMethod | null;
  glCategory?: IGlCategory | null;
  dispatchCountry?: ICountry | null;
  incotermsParity?: ICommonVocabulary | null;
  transactionType?: ICommonVocabulary | null;
  transportType?: ICommonVocabulary | null;
  packagingMaterialWeight?: number | null;
  productFee?: number | null;
}

export class InvoiceIn implements IInvoiceIn {
  constructor(
    public id?: number,
    public appId?: string,
    public invoiceType?: string | null,
    public invoiceNumber?: string | null,
    public originalInvoiceNumber?: string | null,
    public invoiceOperation?: ManageInvoiceOperationType | null,
    public completenessIndicator?: boolean | null,
    public referencedInvoiceId?: number | null,
    public referencedInvoiceNumber?: string | null,
    public modificationIndex?: number | null,
    public supplierBankAccountNumber?: string | null,
    public suppIndividualExemption?: boolean | null,
    public suppSmallBusinessIndicator?: boolean | null,
    public suppCashAccountingIndicator?: boolean | null,
    public invoiceCategory?: InvoiceCategoryType | null,
    public invoiceIssueDate?: dayjs.Dayjs,
    public deliveryDate?: dayjs.Dayjs,
    public deliveryPeriodStart?: dayjs.Dayjs | null,
    public deliveryPeriodEnd?: dayjs.Dayjs | null,
    public accountingDeliveryDate?: dayjs.Dayjs | null,
    public periodicalSettlement?: boolean | null,
    public paymentDate?: dayjs.Dayjs,
    public currencyCode?: string | null,
    public vatExchangeRate?: number | null,
    public accountingExchangeRate?: number | null,
    public invoiceAppearance?: InvoiceAppearanceType | null,
    public electronicInvoiceHash?: string | null,
    public orderNumber?: string | null,
    public orders?: IDocumentReference[] | null,
    public inventoryVouchers?: IDocumentReference[] | null,
    public productFeeOperation?: ProductFeeOperationType | null,
    public pfEvidenceDocumentNo?: string | null,
    public pfEvidenceDocumentDate?: dayjs.Dayjs | null,
    public pfObligatedName?: string | null,
    public pfObligatedRegion?: string | null,
    public pfObligatedPostalCode?: string | null,
    public pfObligatedCity?: string | null,
    public pfObligatedAdditionalAddressDetail?: string | null,
    public pfObligatedTaxNumber?: string | null,
    public additionalData?: string | null,
    public comment?: string | null,
    public roundingIndicator?: boolean | null,
    public invoiceNetAmount?: number | null,
    public invoiceNetAmountLC?: number | null,
    public invoiceVatAmount?: number | null,
    public invoiceVatAmountLC?: number | null,
    public invoiceRounding?: number | null,
    public invoiceGrossAmount?: number | null,
    public invoiceGrossAmountLC?: number | null,
    public navTransactionId?: string | null,
    public glAccountNumber?: string | null,
    public intrastatFlag?: boolean | null,
    public intrastatParityPlace?: string | null,
    public intrastatExchangeRate?: number | null,
    public vatYearMonth?: number | null,
    public glYearMonth?: number | null,
    public productFeeYearMonth?: number | null,
    public intrastatYearMonth?: number | null,
    public glPostFlag?: boolean | null,
    public balance?: number | null,
    public balanceLC?: number | null,
    public balanceState?: InvoiceBalanceState | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null,
    public docFileId?: number | null,
    public doc?: IDoc | null,
    public docFiles?: IDocFile[] | null,
    public invoiceInLines?: IInvoiceInLine[] | null,
    public partner?: IPartner | null,
    public pfObligatedCountry?: ICountry | null,
    public paymentMethod?: IPaymentMethod | null,
    public glCategory?: IGlCategory | null,
    public dispatchCountry?: ICountry | null,
    public incotermsParity?: ICommonVocabulary | null,
    public transactionType?: ICommonVocabulary | null,
    public transportType?: ICommonVocabulary | null,
    public packagingMaterialWeight?: number | null,
    public productFee?: number | null
  ) {
    this.completenessIndicator = this.completenessIndicator ?? false;
    this.suppIndividualExemption = this.suppIndividualExemption ?? false;
    this.suppSmallBusinessIndicator = this.suppSmallBusinessIndicator ?? false;
    this.suppCashAccountingIndicator = this.suppCashAccountingIndicator ?? false;
    this.periodicalSettlement = this.periodicalSettlement ?? false;
    this.roundingIndicator = this.roundingIndicator ?? false;
    this.intrastatFlag = this.intrastatFlag ?? false;
    this.glPostFlag = this.glPostFlag ?? false;
  }
}

export interface IAdvancePosition {
  appId?: string;
  id?: number;
  invoiceNumber?: string;
  partnerId?: number;
  partnerName?: string;
  invoiceIssueDate?: dayjs.Dayjs;
  deliveryDate?: dayjs.Dayjs;
  currencyCode?: string;
  vatExchangeRate?: number;
  vatRateId?: number;
  advanceNetAmount?: number;
  advanceVatAmount?: number;
  advanceGrossAmount?: number;
  usedNetAmount?: number;
  usedVatAmount?: number;
  usedGrossAmount?: number;
  balanceNetAmount?: number;
  balanceVatAmount?: number;
  balanceGrossAmount?: number;
}

export interface IInvoiceInBalance {
  appId?: string;
  partnerId?: number;
  partnerName?: string;
  cashAccountingIndicator?: boolean;
  glCategoryId?: number;
  glCategoryName?: string;
  invoiceId?: number;
  invoiceNumber?: string;
  originalInvoiceNumber?: string | null;
  invoiceIssueDate?: dayjs.Dayjs;
  paymentDate?: dayjs.Dayjs;
  currencyCode?: string | null;
  balance?: number;
  balanceLc?: number;
  hasBalanceFlag?: boolean | null;
}

export class InvoiceInBalance implements IInvoiceInBalance {
  constructor(
    public appId?: string,
    public partnerId?: number,
    public partnerName?: string,
    public cashAccountingIndicator?: boolean,
    public glCategoryId?: number,
    public glCategoryName?: string,
    public invoiceId?: number,
    public invoiceNumber?: string,
    public originalInvoiceNumber?: string | null,
    public invoiceIssueDate?: dayjs.Dayjs,
    public paymentDate?: dayjs.Dayjs,
    public currencyCode?: string | null,
    public balance?: number,
    public balanceLc?: number,
    public hasBalanceFlag?: boolean | null
    ) {}
}

export class AdvancePosition implements IAdvancePosition {
  constructor(
    public appId?: string,
    public id?: number,
    public invoiceNumber?: string,
    public partnerId?: number,
    public partnerName?: string,
    public invoiceIssueDate?: dayjs.Dayjs,
    public deliveryDate?: dayjs.Dayjs,
    public currencyCode?: string,
    public vatExchangeRate?: number,
    public vatRateId?: number,
    public advanceNetAmount?: number,
    public advanceVatAmount?: number,
    public advanceGrossAmount?: number,
    public usedNetAmount?: number,
    public usedVatAmount?: number,
    public usedGrossAmount?: number,
    public balanceNetAmount?: number,
    public balanceVatAmount?: number,
    public balanceGrossAmount?: number
    ) {}
}

export function getInvoiceInIdentifier(invoiceIn: IInvoiceIn): number | undefined {
  return invoiceIn.id;
}
