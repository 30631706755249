import { Component, OnInit, RendererFactory2, Renderer2, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

import { AccountService } from 'app/core/auth/account.service';
import { DataSharingService } from 'app/shared/services/data-sharing.service';
import { AlertService, Alert } from 'app/core/util/alert.service';
// import { MessageService } from 'primeng/api';
// import { Message } from 'primeng/api';

import { PrimeNGConfig } from 'primeng/api';
import { GlobalsService } from 'app/shared/globals/globals.service';

@Component({
  selector: 'auth-main',
  templateUrl: './main.component.html',
  // providers: [AlertService]
})
export class MainComponent implements OnInit, OnDestroy {
  displayMenu = false;
  refreshMenu = false;
  visibleSidebar = false;
  visibleGalleria = false;
  visibleWorking = false;
  // messages: Message[] = [];
  someSubscription: any;
  alerts= 0;
  galleriaImages: any[] = [];
  
  private renderer: Renderer2;

  constructor(
    private accountService: AccountService,
    private titleService: Title,
    public dataSharingService: DataSharingService,
    protected globalsService: GlobalsService,
    private router: Router,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    public alertService: AlertService,
    rootRenderer: RendererFactory2
  ) {
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.dataSharingService.visibleSidebar.subscribe( value => {
      this.visibleSidebar = value;
    });
    this.dataSharingService.nofAlerts.subscribe( value => {
      this.alerts = value;
    });
    this.dataSharingService.visibleGalleria.subscribe( value => {
      this.visibleGalleria = value;
    });
    this.dataSharingService.galleriaImages.subscribe( value => {
      this.galleriaImages = value;
    });
    this.dataSharingService.displayMenu.subscribe( value => {
      this.displayMenu = value;
    });
    this.dataSharingService.visibleWorking.subscribe( value => {
      this.visibleWorking = value;
    });
    
    // this.dataSharingService.messages.subscribe( value => {
    //   if (value.length > 0) {
    //     const globalMessages = this.globalsService.getParam("Messages") as unknown as Message[];
    //     const successMessages: Message[] = [];
    //     if (JSON.stringify(value) !== JSON.stringify(globalMessages)) {
    //       value.forEach(m => {
    //         if( m.severity 
    //             && m.severity !== 'success'
    //             && JSON.stringify(globalMessages).indexOf(JSON.stringify(m)) === -1) {
    //           globalMessages.unshift(m);
    //         } else if (m.severity && m.severity === 'success') {
    //           successMessages.unshift(m);
    //         }
    //       });
    //       this.globalsService.setParam("Messages", globalMessages);
    //       this.dataSharingService.globalMessages.next(globalMessages);
    //       this.messages = successMessages.concat(globalMessages);
    //     } else {
    //       this.messages = value;
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    // try to log in automatically
    this.accountService.identity().subscribe();

    this.someSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitle();
        // You need to tell the router that, you didn't visit or load the page previously, so mark the navigated flag to false as below.
        // this.router.navigated = false;
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });

    this.translateService.setDefaultLang('hu');

    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.updateTitle();
      dayjs.locale(langChangeEvent.lang);
      this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);
      this.translate(langChangeEvent.lang);
    });
  }

  ngOnDestroy(): void {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
  }
  
  translate(lang: string): void {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  // closeMessage( event: any) :void {
  //   if (Array.isArray(event)) {
  //     const nonSuccessMessages: Message[] = [];
  //     event.forEach(m => {
  //       if ( m.severity !== 'success') {
  //         nonSuccessMessages.push(m);
  //       }
  //     })  
  //     this.globalsService.setParam("Messages", nonSuccessMessages);
  //     this.dataSharingService.globalMessages.next(event);
  //     this.dataSharingService.messages.next(event);
  //     this.visibleSidebar = event.length > 0;
  //   }
  // }

  hideSidebar(): void{
    const globalMessages = this.globalsService.getParam("Messages") as unknown as Alert[];
    if (globalMessages.length > 0) {
      const newMessages: Alert[] = [];

      globalMessages.forEach( v => {
        if (v.type !== "success" && v.type !== "info") {
          newMessages.push(v);
        }
      });
      this.globalsService.setParam("Messages",newMessages);
      this.dataSharingService.nofAlerts.next(newMessages.length);
    }
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data['pageTitle'] ?? '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private updateTitle(): void {
    let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (!pageTitle) {
      pageTitle = 'global.title';
    }

    if(pageTitle !== 'magusApp.invoiceOut.home.title' && pageTitle !== 'magusApp.glBooking.home.title'){
      // a GlBookingComponent-ben és az InvoiceOutComponent-ben egyedileg állítjuk be a Title-t
      this.translateService.get(pageTitle).subscribe(title => this.titleService.setTitle(title));
    }
  }
}
